import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { PARTNER_SCENIUM_ROUTE, SIGN_UP_ROUTE } from '../../const/routes.const';
import {
  SCENUM_DISCOUNT,
  SCENUM_NAME,
  SCENUM_PROMO_CODE,
  SCENUM_REF,
  SCENUM_REF_KEY,
} from '../../const/parnters.const';
import PartnerLayout from '../../components/Partners/PartnerLayout';
import scenum_mobile from '../../assets/images/partners/scenum_mobile.png';
import scenum_mobile2x from '../../assets/images/partners/scenum_mobile_2x.png';
import scenum_desktop from '../../assets/images/partners/scenum_desktop.png';
import scenum_desktop2x from '../../assets/images/partners/scenum_desktop_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlueSpan from '../../components/common/typography/BlueSpan';
import PartnerDiscount from '../../components/Partners/PartnerDiscount';
import LinkWrapper from '../../components/common/LinkWrapper';
import { LanguageMap } from '../../const/language.const';

const ScenumPage = (): React.ReactElement => {
  const { language } = useI18next();

  const scenumUrl = useMemo(() => {
    let langPart = `${LanguageMap.en.key}/`;

    if (language === LanguageMap.ru.key) {
      langPart = '';
    }

    return `${PARTNER_SCENIUM_ROUTE}${langPart}?${SCENUM_REF_KEY}=${SCENUM_REF}`;
  }, []);
  return (
    <PartnerLayout
      imgDesktop={<ImageContainer src={scenum_desktop} srcSet={{ _2x: scenum_desktop2x }} />}
      imgMobile={<ImageContainer src={scenum_mobile} srcSet={{ _2x: scenum_mobile2x }} />}
      title={SCENUM_NAME}
      href={scenumUrl}
      description={
        <>
          <p>
            <Trans>
              Scenum.io is a unique service that allows you to automate different actions in your
              browser. This includes managing social media accounts (Facebook, Instagram, VK) or any
              other online activity. The main advantage over other existing solutions is that
              everything is performed in your browser: you do not need to send your account data to
              third-party apps and services, and everything will look as if you are personally
              performing all the activities in your browser. History, cookies, and all other data
              will work to your advantage, and you will only need to kick back and reap the profits.
            </Trans>
          </p>
          <h3>
            <Trans i18nKey="Offer for Octo Browser users">
              Offer for <BlueSpan>Octo Browser users</BlueSpan>
            </Trans>
          </h3>
          <p>
            <Trans i18nKey="This offer is valid for Octo Browser users only. Create an account to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and claim your offer.">
              This offer is valid for Octo Browser users only. <LinkWrapper url={SIGN_UP_ROUTE}>Create an account</LinkWrapper> to claim your
              discount. If you already use Octo Browser, go to the partner's website, sign up, and
              claim your offer.
            </Trans>
          </p>
          <PartnerDiscount
            discount={SCENUM_DISCOUNT}
            href={scenumUrl}
            description={
              <Trans
                i18nKey="partner.scenum.proxy.promo.code.discount"
                tOptions={{ discount: SCENUM_DISCOUNT, promoCode: SCENUM_PROMO_CODE }}
              >
                <strong>{SCENUM_PROMO_CODE}</strong>.
              </Trans>
            }
          />
          <h3>
            <Trans>Scenum key solutions and advantages:</Trans>
          </h3>
          <ul>
            <li>
              <Trans>
                Automatically get hundreds of accounts ready to work for you using pre-existing or
                custom scenarios (likes, posts, creating a FanPage, sending messages, adding
                friends);
              </Trans>
            </li>
            <li>
              <Trans>
                Manage accounts’ advertising functions (create Ads Manager, Business Manager, deal
                with advertising restrictions);
              </Trans>
            </li>
            <li>
              <Trans>Seamless integration with multi-accounting browsers;</Trans>
            </li>
            <li>
              <Trans>
                A digital farming system (full monitoring of accounts’ lifecycle and Telegram bot
                notifications);
              </Trans>
            </li>
            <li>
              <Trans>Our own wiki with a detailed description of functions and actions;</Trans>
            </li>
            <li>
              <Trans>A responsive technical support team;</Trans>
            </li>
            <li>
              <Trans>Built-in support for mobile proxies.</Trans>
            </li>
          </ul>
        </>
      }
    />
  );
};

export default ScenumPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["scenumPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
